// import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";

let messages = {
  "error_msg"	:	"Oops, something went wrong. Try again. If the problem persists, please contact Support.",
};

const i18n = createI18n({
    locale: "en", // set locale
    fallbackLocale: "en", // set fallback locale
    messages, // set locale messages
  });

const app = createApp(App);

app.use(i18n);
app.use(router);
app.mount("#app");

export default i18n;