/* eslint-disable */
const axios = require('axios');


/* ===== Get Country Code & ID ===== */
const gsCountryCodeID = async () => {
  const res = await axios.get('/api/googleSheet/gsCountryCode');
  const countryCodeteSheet = res.data.filter(sheet => sheet.range.includes("CountryCode_id"));

  const countryCodesIDsArr = [];
  let countryCodesIDs = {};
  
  if (res && res.data && res.data !== 'Server Error') {
    countryCodeteSheet[0].values.forEach((key, index3) => {
      if(index3 > 0) {
        countryCodesIDs = {};
      countryCodeteSheet[0].values[0].forEach((countryCode, index) => {
          if(index > 0) {
            countryCodesIDs[countryCode] = key[index] ? key[index] : "";
          }
        })
        countryCodesIDsArr.push(countryCodesIDs);
      }
      })
  }
  return countryCodesIDsArr;
}

/* ===== Get All data from Google Sheets ===== */

const getGoogleSheetsData = (gsData) => {
  /* ===== Get Translate ===== */
  const translateSheet = gsData.filter((sheet) =>
    sheet.range.includes("Translate")
  );

  const linkGroupsNames = {};
  const translate = {};
  translateSheet[0].values[0].forEach((languageTitle, index) => {
    if (index > 0) {
      translate[languageTitle] = {};
      Object.keys(translate).forEach((lang, index2) => {
        translateSheet[0].values.forEach((key, index3) => {
          // Save the translation inside the object (translate)
          if (index3 > 0 && lang) {
            translate[lang][key[0]] = key[index2 + 1]
          }
          // Save the groups's names inside the object (linkGroupsNames) to use them
          // in the part (Get Links) below.
          if (key[index2].includes("category_")) {
            linkGroupsNames[key[index2]] = translate[lang][key[0]]
          }
        })
      })
    }
  }
  );

  /* ===== Get Tips & Events ===== */
  const tipsSheet = gsData.filter((sheet) => sheet.range.includes("Tips"));
  const tipsArr = [];
  const eventsArr = [];
  let tipObj = {};

  tipsSheet[0].values.forEach((key, index3) => {
    if (index3 > 0) {
      tipObj = {};
      tipsSheet[0].values[0].forEach((tip, index) => {
        if (index > 0) {
          tipObj[tip] = key[index] ? key[index] : "";
        }
      });
      if (tipObj && tipObj.text) {
        if (tipObj.type === "Tip") {
          tipsArr.push(tipObj);
        } else {
            eventsArr.push(tipObj);
        }
      }
    }
  });

  /* ===== Get Standard Links ===== */

  const linksSheet = gsData.filter((sheet) => sheet.range.includes("Links"));
  let linkObj = {};
  let linksGroups = {};

  // Create a new array per group with the name of this group
  Object.keys(linkGroupsNames).forEach(linkGroupName => {
    const linkKey = `${linkGroupName}LinksArr`;
    linksGroups[linkKey] = []
  })

  linksSheet[0].values.forEach((link, key) => {
    if (link[0] !== "") {
      if (key > 0) {
        linkObj = {};
        linksSheet[0].values[0].forEach((keyTitle, index) => {
          if (index > 0) {
            // console.log(link[index])
            linkObj[keyTitle] = link[index] ? link[index] : "";
          }
        });
        Object.keys(linkGroupsNames).forEach(linkGroupName => {
            if (linkObj.link_group === linkGroupsNames[linkGroupName]) {
              Object.keys(linksGroups).forEach(linkGroup => {
                // console.log(linksGroups[linkGroup])
                // console.log(linkObj)
                if (linkGroup.includes(linkGroupName) && linkObj.link_group && linkObj.link_name && linkObj.link_url && linkObj.frontSide_icon_name && linkObj.backSide_icon_name) {
                  linksGroups[linkGroup].push(linkObj);
                }
              })
            }
        })
      }
    }
  });

  /* ===== Get Calendar(s) ===== */
  const calendarsSheet = gsData.filter((sheet) =>
    sheet.range.includes("Calendar")
  );

  const calendarsArr = [];
  let calendarObj = {};
  let locale;

  calendarsSheet[0].values.forEach((calendar, index) => {
    calendarObj = {};
    if (index > 0) {
      calendarsSheet[0].values[0].forEach((title, key) => {
        if (title != "locale") {
          calendarObj[title] = calendar[key];
        }
        else {
          if (!locale) {
            if (calendar[key]) {
              if (calendar[key] != "") {
                locale = calendar[key];
              } else {
                locale = "English";
              }
            } else {
              locale = "English";
            }
          }
        }
      })
      calendarsArr.push(calendarObj);
    }
  })

  const calendars = {
    calendarsArr : calendarsArr,
    locale: locale
  }

  return {
    translate: translate ? translate : null,
    tips_events: { tipsArr, eventsArr },
    links: linksGroups,
    calendars: calendars
  };
};
// ===============================================

module.exports = {
  gsCountryCodeID,
  getGoogleSheetsData,
};